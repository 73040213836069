import React from 'react'
import Link from 'next/link'

interface ButtonProps {
  children: string;
  isLink: boolean;
  href: string;
  target?: string;
  icon: React.ReactNode;
  className?: string;
}

const ButtonWhite = ({ children, isLink, href, target, icon, className }: ButtonProps) => {
  if (isLink) return (
    <Link
      href={href}
      className={`group border-white text-white hover:bg-white hover:text-primary w-full px-18 inline-flex justify-center items-center border text-14 font-bold uppercase rounded-10 transition-colors lg:w-auto lg:text-18 ${className}`}>
      <div className="flex items-center justify-center w-30 h-30 lg:w-44 lg:h-44">
        <i className="w-22 h-22 lg:w-30 lg:h-30 transform transition-transform -rotate-90 group-hover:rotate-0">{icon}</i>
      </div>
      <div className="ml-15 relative overflow-hidden">
        <div className="py-18 transition-transform group-hover:-translate-y-full">{children}</div>
        <div className="py-18 absolute inset-0 translate-y-full transition-transform group-hover:translate-y-0">{children}</div>
      </div>
    </Link>
  )

  return (
    <a
      href={href}
      target={target}
      className={`group border-white text-white hover:bg-white hover:text-primary w-full px-18 inline-flex justify-center items-center border text-14 font-bold uppercase rounded-10 transition-colors lg:w-auto lg:text-18 ${className}`}>
      <div className="flex items-center justify-center w-30 h-30 lg:w-44 lg:h-44">
        <i className="w-22 h-22 lg:w-30 lg:h-30 transform transition-transform -rotate-90 group-hover:rotate-0">{icon}</i>
      </div>
      <div className="ml-15 relative overflow-hidden">
        <div className="py-18 transition-transform group-hover:-translate-y-full">{children}</div>
        <div className="py-18 absolute inset-0 translate-y-full transition-transform group-hover:translate-y-0">{children}</div>
      </div>
    </a>
  )
}

export default ButtonWhite