import { useRef } from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import useTranslation from '@hooks/useTranslation'
import Header from '@components/sections/Header'
import ButtonWhite from '@components/ui/ButtonWhite'
import ArrowButton from '@components/icons/ArrowButton'

const DynamicComponentWithNoSSR = dynamic(
  () => import('@components/ui/RotatingNumber'),
  { ssr: false }
);

const NotFoundPage = () => {
  const { t } = useTranslation()
  const headerEl = useRef<HTMLDivElement>(null)

  return (
    <>
      <Head>
        <title>{t('seo.title')}</title>
        <meta name="description" content={t('seo.description') as string} />
      </Head>
      <main className="flex flex-col items-center justify-center bg-primary min-h-screen text-white text-center">
        <Header ref={headerEl} theme="primary" appIsReady={true} />
        <div className="container px-40">
          <h1 className="text-140">
            <DynamicComponentWithNoSSR number={t('404.title')} length={3} />
          </h1>
          <p className="text-18 font-medium">{t('404.subtitle')}</p>
          <ButtonWhite
            isLink
            href={t('404.cta.href')}
            icon={<ArrowButton />}
            target="_blank"
            className="mt-30 lg:mt-45"
          >
            {t('404.cta.label')}
          </ButtonWhite>
        </div>
      </main>
    </>
  )
}

export default NotFoundPage